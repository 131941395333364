import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IBankAccount } from 'redux/reducers/bankAccounts/types';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { getCurrencyData } from 'redux/reducers/currency/selectors';
import { ICurrencyDataItem } from 'redux/reducers/currency/types';

const TableBodyBankAccount: React.FC<IBankAccount> = ({
	beneficiary_name,
	beneficiary_country,
	beneficiary_address,
	bank_address,
	bank_name,
	bank_country,
	swift_bic,
	account_number,
	bank_currency,
	note,
}) => {
	const currencyList = useSelector(getCurrencyData);
	const dispatch = useDispatch();
	const [currency, setCurrency] = useState<ICurrencyDataItem>();

	useEffect(() => {
		setCurrency(currencyList.filter((item) => item.id === bank_currency)[0]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bank_currency, currencyList]);

	useEffect(() => {
		dispatch(currencyDataRequest());
	}, [dispatch]);

	return (
		<div className="payment-methods-body">
			<div className="payment-methods-list">
				<div className="payment-methods-item">
					<p>Currency</p>
					{currency && (
						<span className="coin coin--type8">
							<span className="coin__icon">
								<img
									src={`/img/currencies/${currency?.code.toLowerCase()}.svg`}
									alt={currency?.code}
								/>
							</span>
							<div className="coin__wrap">
								<span className="coin__text coin__text---upper">{currency.code.toUpperCase()}</span>
								<span className="coin__text-more">{currency.name}</span>
							</div>
						</span>
					)}
				</div>
				<div className="payment-methods-item">
					<p>Beneficiary Name</p>
					<span>{beneficiary_name}</span>
				</div>
				<div className="payment-methods-item">
					<p>Beneficiary Bank</p>
					<span>{bank_name}</span>
				</div>
				<div className="payment-methods-item payment-methods-item--text-right">
					<p>Account Number</p>
					<span>{account_number}</span>
				</div>
				{/* <div className="payment-methods-item">
			<p>Beneficiary Address</p>
			<span>{beneficiary_address}</span>
		</div>
		<div className="payment-methods-item">
			<p>Beneficiary Country</p>
			<span>{beneficiary_country}</span>
		</div>
		<div className="payment-methods-item">
			<p>Bank Address</p>
			<span>{bank_address}</span>
		</div>
		<div className="payment-methods-item">
			<p>Bank Country</p>
			<span>
				{' '}
				{bank_country === ' Caribbean Netherlands' ? '🇧🇶 Caribbean Netherlands' : bank_country}
			</span>
		</div>
		<div className="payment-methods-item">
			<p>SWIFT / BIC</p>
			<span>{swift_bic}</span>
		</div> */}
			</div>
			{/* <div className="payment-methods-list payment-methods-list--type2">
		<div className="payment-methods-item">
			<p>Notes</p>
			<span>{note}</span>
		</div>
	</div> */}
		</div>
	);
};

export default TableBodyBankAccount;
