import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import image from 'assets/dist/img/email-icon2.svg';
import IconSvg from 'ui/Svg/IconSvg';
import { IPopUp } from '../types';

const ResetTwoFaPopUp: FC<IPopUp> = ({ open, closeModal }) => (
	<Popup open={open} closeOnDocumentClick onClose={closeModal}>
		<div className="popup">
			{/* eslint-disable-next-line jsx-a11y/control-has-associated-label,react/button-has-type */}
			<button className="popup__close-btn" onClick={closeModal} />
			<div className="popup-header popup-header--mb-20">
				<p className="popup-header__title">Request Sent</p>
			</div>
			<div className="popup-icon popup-icon--type2">
				<IconSvg name="success-popup" />
			</div>
			<div className="popup-body">
				<div className="popup-text mb-0">
					<p>
						Your 2FA reset request is being reviewed. Once approved you will receive a reset link
						via email.
					</p>
				</div>
			</div>
		</div>
	</Popup>
);

export default ResetTwoFaPopUp;
