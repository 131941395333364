export const endpoint = {
	auth: {
		LOGIN: '/login',
		REFRESH_TOKEN: '/token/refresh',
		REGISTRATION: '/register',
		LOGOUT: '/logout',
		RESET_PASSWORD: '/password_reset',
		NEW_PASSWORD: '/user/password',
		RESET_EMAIL_CONFIRM: '/reset-token',
		EMAIL_CONFIRM: '/email_confirmation',
		IP_CONFIRM: '/whitelist_ip',
		GENERATE_SMS: '/generate_sms',
		RESET_TWOFA: '/2fa/reset',
		GET_USER: '/user',
		RESET_TWOFA_TOKEN: '/2fa/generate_secret_code',
		TWOFA_UPDATE: '/2fa/enable/by_token',
	},
	settings: {
		VERIFICATION: '/settings/verification/upload',
		GENERATE_SECRET_SMS: '/settings/2fa/generate_secret_key',
		TWO_FA_ENABLE: '/settings/2fa/enable',
		CHECK_TWO_FA_ENABLED: '/settings/2fa/check_enabled',
		DISABLE_TWO_FA: '/settings/2fa/disable',
	},
	register: {
		register: '/register',
	},
	confirmation: {
		CONFIRMATION: '/confirmation',
		CONFIRMATION_DOCUMENTS: '/confirmation/documents',
		CONFIRMATION_ENABLE_CHANGE_TWO_FA: '/confirmation/2fa/enable',
		CONFIRMATION_PASSWORD: '/confirmation/password',
		CONFIRMATION_EMAIL: '/confirmation/email',
		CONFIRMATION_TWO_FA: '/confirmation/2fa/generate',
		COMPANY: '/confirmation/company',
	},
	transactions: {
		GET_CRYPTO_HISTORY: '/transactions/cryptos',
		GET_FIAT_HISTORY: '/transactions/fiats',
		GET_TRADE_HISTORY: '/transactions/trades',
		GET_TRADE_QUOTE: '/transactions/trades/request_quote',
		GET_TRADE_EXCHANGE_RATE: '/transactions/trades/exchange_rate',
		MAKE_TRADE: '/transactions/trades/exchange',
		GET_TOP_PAIR: '/top-pairs',
		CREATE_FIAT_WITHDRAW: '/transactions/fiats/withdraw',
		CREATE_CRYPTO_WITHDRAW: '/transactions/cryptos/withdraw',
		GET_FEES_BY_ASSET: (id: number | string) => `/user/fee/${id || ''}`,
		GET_LIMITS_BY_ASSET: (id: number) => `/user/limit/${id}`,
		POST_FIAT_DEPOSIT_INVOICE_CREATE: '/invoice/create',
	},
	wallets: {
		WALLETS: '/wallets',
		VALIDATE_WALLET: '/wallet_address/validate_address',
	},
	deposits: {
		DEPOSIT_ADDRESS: '/address/deposit',
	},
	currency: {
		GET_CURRENCY: '/assets/user',
		GET_FEES: '/user/fees',
		GET_LIMITS: '/limits/user',
	},
	walletAddresses: {
		WALLET_ADDRESSES: '/wallet_address',
		WALLET_ADDRESS: (id: number) => `/wallet_address/${id}`,
	},
	bankAccounts: {
		BANK_ACCOUNTS: '/bank_account',
		BANK_ACCOUNT: (id: number) => `/bank_account/${id}`,
	},
	bonusSystem: {
		GET_BONUS_WALLETS: '/referral/wallets',
		GET_REFERRALS_COUNT: '/referral/count',
		TRANSFER_BONUS_WALLET: '/referral/wallet/transfer',
	},
	download: {
		GET_DOWNLOAD_KEY: '/generate_secret_for_download_file',
		GET_FILE_DOWNLOAD_DEPOSIT_WITHDRAW: (
			currency: string,
			idUser: number,
			fileName: string,
			key: string,
			type: string,
		) => `/transactions/${currency}/report/${idUser}/${fileName}/${key}/${type}`,
		GET_FILE_DOWNLOAD_DEPOSIT_TRADE: (idUser: string | number, fileType: string, key: string) =>
			`/trade/report/trades/${idUser}/${fileType}/${key}`,
	},
	fee: {
		GET_FEE: '/user/calculate/fee',
		GET_FEE_VALUE: '/user/fee',
		GET_SPREAD_FEE: '/user/spreadFee',
	},
	support: '/support',
};
