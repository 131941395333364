import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPopUp } from 'redux/reducers/popUp/selectors';
import { getPopUpClose } from 'redux/reducers/popUp/reducer';
import VerificationPopUp from './Settings/VerificationPopUp';
import SupportPopUp from './Settings/SupportPopUp';
import SuccessEmail from './SuccessEmail/SuccessEmail';
import RegistrationComplete from './RegistrationComplete/RegistrationComplete';
import SuccessPopUp from './SuccessPopUp/SuccessPopUp';
import WarningPopUp from './WarningPopUp/WarningPopUp';
import ErrorPopUp from './ErrorPopUp/ErrorPopUp';
import ResetPasswordPopUp from './ResetPasswordPopUp/ResetPasswordPopUp';
import ConfirmTrade from './ConfirmTrade/ConfirmTrade';
import SuccessTrade from './SuccessTrade/SuccessTrade';
import ErrorTrade from './ErrorTrade/ErrorTrade';
import SuccessLogin from './SuccessLogin/SuccessLogin';
import GoToTrade from './GoToTrade/GoToTrade';
import ReferralSuccessPopUp from './ReferralFeeSuccessPopUp/ReferralSuccessPopUp';
import WithdrawConfirm from './WithdrawConfirmPopUp/WithdrawConfirm';
import VerificationErrorPopUp from './Settings/VerificationErrorPopUp';
import ResetTwoFaPopUp from './ResetTwoFaPopUp';
import SuccessUpdateTwoFaPopUp from './SuccessUpdateTwoFaPopUp';
import Disabled2FAPopUp from './Disabled2FAPopUp/Disabled2FAPopUp';
import Enabled2FAPopUp from './Enabled2FAPopUp/Enabled2FAPopUp';
import NewPasswordSuccess from './NewPasswordSuccess/NewPasswordSuccess';

const PopUp = () => {
	const dispatch = useDispatch();
	const popUpState = useSelector(getPopUp);
	const [open, setOpen] = useState(false);

	const closeModal = () => {
		// console.log(popUpState.popUpText);
		// const popupContent: any = document.getElementsByClassName('popup-content');
		// if (popupContent.length) {
		// 	Array.from(popupContent).forEach((el: any) => {
		// 		el.classList.add('is-closing');
		// 	});
		// }
		// setTimeout(() => {
		// 	setOpen(false);
		// 	dispatch(getPopUpClose());
		// }, 300);
		setOpen(false);
		dispatch(getPopUpClose());
	};
	useEffect(() => {
		setOpen(true);
	}, [popUpState.popUpText]);
	useEffect(() => {
		setOpen(false);
	}, []);
	const popUpContent = () => {
		switch (popUpState.popUpText) {
			case 'verificationSuccess':
				return <VerificationPopUp open={open} closeModal={closeModal} />;
			case 'verificationError':
				return <VerificationErrorPopUp open={open} closeModal={closeModal} />;
			case 'supportSuccess':
				return <SupportPopUp open={open} closeModal={closeModal} />;
			case 'successEmail':
				return <SuccessEmail open={open} closeModal={closeModal} />;
			case 'successRegistration':
				return <RegistrationComplete open={open} closeModal={closeModal} />;
			case 'successPopUp':
				return <SuccessPopUp open={open} closeModal={closeModal} {...popUpState.data} />;
			case 'warningPopUp':
				return <WarningPopUp open={open} closeModal={closeModal} {...popUpState.data} />;
			case 'errorPopUp':
				return <ErrorPopUp open={open} closeModal={closeModal} {...popUpState.data} />;
			case 'resetPasswordSuccess':
				return <ResetPasswordPopUp open={open} closeModal={closeModal} />;
			case 'newPasswordSuccess':
				return <NewPasswordSuccess open={open} closeModal={closeModal} />;
			case 'resetTwoFaSuccess':
				return <ResetTwoFaPopUp open={open} closeModal={closeModal} />;
			case 'confirmTrade':
				return <ConfirmTrade open={open} closeModal={closeModal} {...popUpState.data} />;
			case 'successTrade':
				return <SuccessTrade open={open} closeModal={closeModal} />;
			case 'errorTrade':
				return <ErrorTrade open={open} closeModal={closeModal} />;
			case 'successLogin':
				return <SuccessLogin open={open} closeModal={closeModal} />;
			case 'goToTrade':
				return <GoToTrade open={open} closeModal={closeModal} />;
			case 'referralSuccessPopUp':
				return <ReferralSuccessPopUp open={open} closeModal={closeModal} {...popUpState.data} />;
			case 'withdrawConfirmed':
				return <WithdrawConfirm open={open} closeModal={closeModal} data={popUpState.data?.data} />;
			case 'resetTwoFa':
				return <ResetTwoFaPopUp open={open} closeModal={closeModal} />;
			case 'updateTwoFaSuccess':
				return <SuccessUpdateTwoFaPopUp open={open} closeModal={closeModal} />;
			case 'disabled2FAPopUp':
				return <Disabled2FAPopUp open={open} closeModal={closeModal} />;
			case 'enabled2FAPopUp':
				return <Enabled2FAPopUp open={open} closeModal={closeModal} />;
			default:
				null;
		}
		return null;
	};
	return <div>{popUpContent()}</div>;
};

export default PopUp;
