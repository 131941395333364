// ==========================================:
import React from 'react';
import logo from 'assets/dist/img/logo-footer.svg';
import { popUpOpen } from 'redux/reducers/popUp/reducer';
import { navList } from 'routes/routesList';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

const Footer = () => {
	const { pathname } = useLocation();

	const dispatch = useDispatch();

	const handleFooterClassName = () => {
		if (
			pathname === '/login' ||
			pathname === ROUTES.CREATE_PASSWORD ||
			pathname === ROUTES.CREATE_TWOFA ||
			pathname === ROUTES.REGISTRATION.CONFIRMATION ||
			pathname === ROUTES.REGISTRATION.TWOFA
		) {
			return 'footer footer__visible';
		}
		return 'footer';
	};
	return (
		<footer className={handleFooterClassName()}>
			<div className="footer-container">
				<a href="mailto:support@kaiserex.com" className="footer__support">
					support@kaiserex.com
				</a>
				<div className="footer-nav">
					<ul>
						<li>
							<a href={navList.termsOfUse.path} target="_blank" rel="noreferrer">
								Terms & Conditions
							</a>
						</li>
						<li>
							<a href={navList.privacyPolicy.path} target="_blank" rel="noreferrer">
								Privacy Policy
							</a>
						</li>
					</ul>
				</div>
			</div>
		</footer>
	);
};
export default Footer;
