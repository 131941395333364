import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Footer from 'layouts-elements/Footer';
import Dashboard from '../../layouts/Dashboard';
import CreatePasswordForm from './CreatePasswordForm/CreatePasswordForm';
import loginBannerImage from '../../assets/dist/img/login-banner@2x-min.png';
import Logo from '../../assets/img/top_logo.svg';

const CreatePassword = () => {
	return (
		<>
			<Helmet>
				<body className="login-page" />
			</Helmet>
			<Dashboard>
				<section className="login-section">
					<a href="/" className="login-section__logo">
						<img src={Logo} alt="" />
					</a>
					<div className="container ">
						<div className="login">
							<div className="login-form__title mb-16">
								<p>Create New Password</p>
								<p className="login-form__subtitle">Please enter a strong and unique password.</p>
							</div>
							<div className="login-form ">
								<CreatePasswordForm />
							</div>
						</div>
					</div>
				</section>
			</Dashboard>
		</>
	);
};

export default CreatePassword;
