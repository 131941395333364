import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';
import Content from 'layouts-elements/Content';
import Footer from 'layouts-elements/Footer';
import HeaderTop from 'layouts-elements/Header';
// import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { motion } from 'framer-motion/dist/framer-motion';
import WalletSideBar from 'components/Wallets/WalletSideBar/WalletSideBar';
import HeaderActions from 'layouts-elements/HeaderActions';
import { IDashboard } from './types';

// ==========================================:
const Dashboard: FC<IDashboard> = ({ children, title }) => {
	const pageTitle = title ? `| ${title}` : '';
	const history = useHistory();
	// const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated);
	const [showHeader, setShowHeader] = useState(false);

	useEffect(() => {
		if (history.location.pathname === '/terms' || history.location.pathname === '/privacy') {
			setShowHeader(true);
			return;
		}
		setShowHeader(false);
	}, [history]);

	return (
		<div className={showHeader ? 'page-wrap' : ''}>
			<Helmet>
				<title>{`${String(APP_NAME)} ${pageTitle}`}</title>
			</Helmet>
			{showHeader && <WalletSideBar />}
			<motion.div
				className={showHeader ? 'wallet-content' : 'wallet'}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { duration: 0.4 } }}
				exit={{ opacity: 0, transition: { duration: 0.4 } }}
			>
				{showHeader && <HeaderActions />}
				<Content>{children}</Content>
				{!showHeader && <Footer />}
			</motion.div>
		</div>
	);
};

export default Dashboard;
