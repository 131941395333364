import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import { APP_NAME } from 'services/constants/env';
import Content from 'layouts-elements/Content';
import Footer from 'layouts-elements/Footer';
import HeaderTop from 'layouts-elements/Header';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { motion } from 'framer-motion/dist/framer-motion';

import { getUserData } from 'redux/reducers/auth/selectors';

import { createBrowserHistory } from 'history';
import { IAuth } from './types';
// ==========================================:
const Auth: FC<IAuth> = ({ children, title }) => {
	const pageTitle = title ? `| ${title}` : '';
	const login = useSelector(getUserData);

	const history = createBrowserHistory({ forceRefresh: true });

	useEffect(() => {
		if (login === null) {
			history.push('/login');
		}
	}, [login, history]);
	return (
		<>
			<Helmet>
				<title>{`${String(APP_NAME)} ${pageTitle}`}</title>
			</Helmet>
			{/* <motion.div
				className="wrapper"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { duration: 0.4 } }}
				exit={{ opacity: 0, transition: { duration: 0.4 } }}
			> */}
			{/* <HeaderTop /> */}
			{/* <Content>{children}</Content>
				<Footer />
			</motion.div> */}
			<div className="wrapper">
				{/* <HeaderTop /> */}
				<Content>{children}</Content>
				<Footer />
			</div>
		</>
	);
};

export default Auth;
